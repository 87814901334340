













































































































































































import Vue from 'vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardTitle, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import VuexyLogo from '@/vuexy/layouts/components/Logo.vue'
import { required, email } from '@/vuexy/utils/validations/validations'
import { togglePasswordFunc } from '@/vuexy/mixins/ui/forms'
import ToastificationContent from '@/vuexy/components/toastification/ToastificationContent.vue'

export default Vue.extend({
  components: {
    VuexyLogo,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      regEmail: '',
      username: '',
      password: '',
      status: '',

      // validation rules
      required,
      email,
    }
  },
  methods: {
    validationForm() {
      const { registerForm }: any = this.$refs

      registerForm.validate()
        .then((success: boolean) => {
          if (success) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Form Submitted',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          }
        })
    },
  },
  setup() {
    const {
      passwordFieldType,
      passwordToggleIcon,
      togglePasswordVisibility,
    } = togglePasswordFunc()

    return {
      passwordFieldType,
      passwordToggleIcon,
      togglePasswordVisibility,
    }
  },
})
